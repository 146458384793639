import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = () => {
  return (
    <div className={`bg-[#FCFCFC] dark:bg-black`}>
      <Header />
      <main className="content">
        <Outlet />
      </main>
      <>
        <Footer />
        <ScrollToTop />
      </>
    </div>
  );
};

export default Layout;
