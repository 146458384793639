import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../providers";
import DOMPurify from "dompurify";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const { isRTL } = useTheme();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: DOMPurify.sanitize(value),
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (recaptchaRef.current) {
      const recaptchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/send-email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...formData, recaptchaToken }),
          }
        );
        if (response.ok) {
          setSubmitSuccess(true);
          setSubmitError("");
        } else {
          const data = await response.json();
          setSubmitSuccess(false);
          setSubmitError(data.message || "Something went wrong.");
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
        setSubmitSuccess(false);
        setSubmitError("Something went wrong.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <section id="contact" className="overflow-hidden py-16 md:py-20 lg:py-28">
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div
              className={`mb-12 rounded-sm bg-white px-8 py-11 shadow-three dark:bg-gray-dark sm:p-[55px] lg:mb-5 lg:px-8 xl:p-[55px] ${
                isRTL ? "text-right" : ""
              }`}
            >
              <h2 className="mb-3 text-2xl font-bold text-black dark:text-white sm:text-3xl lg:text-2xl xl:text-3xl">
                {t("contact.title")}
              </h2>
              <p className="mb-12 text-base font-medium text-body-color">
                {t("contact.subtitle")}
              </p>
              <form onSubmit={handleSubmit}>
                <div className="-mx-4 flex flex-wrap">
                  <div className="w-full px-4 md:w-1/2">
                    <div className="mb-8">
                      <label
                        htmlFor="name"
                        className="mb-3 block text-sm font-medium text-dark dark:text-white"
                      >
                        {t("contact.yourname")}
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={t("contact.name_placeholder")}
                        className={`${isRTL ? "text-right" : ""}
                        border-stroke w-full rounded-sm border bg-[#f8f8f8] px-6 py-3 
                        text-base text-body-color outline-none focus:border-primary dark:border-transparent
                         dark:bg-[#2C303B] dark:text-body-color-dark dark:shadow-two dark:focus:border-primary 
                         dark:focus:shadow-none`}
                      />
                    </div>
                  </div>
                  <div className={`w-full px-4 md:w-1/2`}>
                    <div className="mb-8">
                      <label
                        htmlFor="email"
                        className="mb-3 block text-sm font-medium text-dark dark:text-white"
                      >
                        {t("contact.youremail")}
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={t("contact.email_placeholder")}
                        className={`
                          ${isRTL ? "text-right" : ""}
                          border-stroke w-full rounded-sm border bg-[#f8f8f8] 
                        px-6 py-3 text-base text-body-color outline-none focus:border-primary
                         dark:border-transparent dark:bg-[#2C303B] dark:text-body-color-dark 
                         dark:shadow-two dark:focus:border-primary dark:focus:shadow-none`}
                      />
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="mb-8">
                      <label
                        htmlFor="message"
                        className="mb-3 block text-sm font-medium text-dark dark:text-white"
                      >
                        {t("contact.yourmessage")}
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        rows={5}
                        value={formData.message}
                        onChange={handleChange}
                        placeholder={t("contact.message_placeholder")}
                        className={`
                          ${isRTL ? "text-right" : ""}
                          border-stroke w-full resize-none rounded-sm border bg-[#f8f8f8] 
                          px-6 py-3 text-base text-body-color outline-none focus:border-primary 
                          dark:border-transparent dark:bg-[#2C303B] dark:text-body-color-dark dark:shadow-two
                           dark:focus:border-primary dark:focus:shadow-none`}
                      ></textarea>
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY as string}
                      size="invisible"
                    />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`rounded-sm bg-primary px-9 py-4 text-base font-medium text-white shadow-submit duration-300 hover:bg-primary/90 dark:shadow-submit-dark ${
                        isSubmitting ? "cursor-not-allowed opacity-50" : ""
                      }`}
                    >
                      {isSubmitting ? "Submitting..." : t("contact.submit")}
                    </button>
                    {submitError && (
                      <p className="mt-2 text-sm text-red-500">{submitError}</p>
                    )}
                    {submitSuccess && (
                      <p className="mt-2 text-sm text-green-500">
                        Form submitted successfully!
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
