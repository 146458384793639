import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DottedLoader from "../DottedLoader";

const PaymentStatus: React.FC = () => {
  const [status, setStatus] = useState<string>("loading");
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("resourcePath");

    const regex = /checkouts\/([^\/]+)\/payment/;
    if (!path || !regex.test(path)) {
      setStatus("not-found");
      navigate("/");
      return;
    }

    const sendMessage = (type: string, details: any) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: type,
            details: details,
          })
        );
      } else {
        setStatus("error");
      }
    };

    if (window.ReactNativeWebView) {
      setStatus("redirecting");
      sendMessage("fetchUrl", {
        url: `/payment/status?id=${getCheckoutId(path)}`,
      });
    } else {
      setStatus("error");
    }
  }, []);

  const getCheckoutId = (url: string): string | null => {
    const regex = /checkouts\/([^\/]+)\/payment/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  return (
    <div className="p-4 border rounded-lg flex flex-col items-center justify-center min-h-screen">
      {status === "loading" && (
        <div className="text-center">
          <DottedLoader />
          <p className="mt-4 text-lg font-semibold">Loading...</p>
        </div>
      )}
      {status === "redirecting" && (
        <div className="text-center">
          <DottedLoader />
          <p className="mt-4 text-lg font-semibold">Redirecting...</p>
          <p className="mt-2 text-sm text-gray-600">
            Please wait while we process your Payment request. Do not close this
            page.
          </p>
        </div>
      )}
      {status === "error" && (
        <div className="text-center">
          <p className="text-lg font-semibold text-red-600">
            Something went wrong.
          </p>
          <p className="mt-2 text-sm text-gray-600">
            Please try again later or contact support.
          </p>

          <button
            onClick={() =>
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: "GoToHome",
                  details: { message: "goToHome" },
                })
              )
            }
            className="mt-4 px-4 py-2 bg-primary text-white rounded"
          >
            Go to Home
          </button>
        </div>
      )}
      {status === "not-found" && (
        <div className="text-center">
          <p className="text-lg font-semibold text-red-600">Page Not Found</p>
          <p className="mt-2 text-sm text-gray-600">
            The requested payment resource could not be found. Please check the
            URL or contact support.
          </p>
        </div>
      )}
    </div>
  );
};

export default PaymentStatus;
