// import { Menu } from "../../types/index";

const menuData: any[] = [
  {
    id: 1,
    title: "home",
    path: "/",
    newTab: false,
  },
  {
    id: 2,
    title: "about",
    path: "/about",
    newTab: false,
  },
  {
    id: 3,
    title: "contactus",
    path: "/contact",
    newTab: false,
  },
];
export default menuData;
