import { useTranslation } from "react-i18next";
import SectionTitle from "../Common/SectionTitle";
import CountdownTimer from "../CountdownTimer";

const Features = () => {
  const { t } = useTranslation();

  const launchDate = "2024-08-19T00:00:00Z";
  const [days, hours, minutes, seconds] = [
    t("common.days"),
    t("common.hours"),
    t("common.minutes"),
    t("common.seconds"),
  ];
  return (
    <>
      <section id="features" className="py-16 md:py-20 lg:py-28">
        <div className="container">
          <SectionTitle
            title={t("features.title")}
            paragraph={t("features.paragraph")}
            center
          />
          <CountdownTimer
            labels={[days, hours, minutes, seconds]}
            launchDate={launchDate}
          />
        </div>
      </section>
    </>
  );
};

export default Features;
