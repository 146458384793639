import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
      }}
    >
      <div style={{ direction: "rtl", textAlign: "right" }}>
        <br/>
        <strong>سياسة الخصوصية</strong>
        <p>
          <br/>
          <strong>آخر تحديث:</strong> [28-06-2024]
        </p>
        <p>
          تقوم Linguist ("نحن" أو "خاصتنا") بتشغيل موقع www.linguistedu.com
          (يشار إليه بـ "الخدمة"). تُعلمك هذه الصفحة بسياساتنا المتعلقة بجمع
          واستخدام والكشف عن البيانات الشخصية عند استخدامك لخدمتنا، والخيارات
          التي لديك فيما يتعلق بتلك البيانات.
        </p>
        <p>
          نستخدم بياناتك لتقديم وتحسين الخدمة. باستخدام الخدمة، فإنك توافق على
          جمع واستخدام المعلومات وفقًا لهذه السياسة.
        </p>

        <br/>
        <strong>جمع المعلومات واستخدامها</strong>
        <p>
          نجمع عدة أنواع مختلفة من المعلومات لأغراض متنوعة لتقديم وتحسين خدمتنا
          لك.
        </p>

        <br/>
        <strong>أنواع البيانات التي يتم جمعها</strong>
        <h3>البيانات الشخصية</h3>
        <p>
          أثناء استخدام خدمتنا، قد نطلب منك تزويدنا بمعلومات شخصية معينة يمكن
          استخدامها للتواصل معك أو تحديد هويتك ("البيانات الشخصية"). قد تشمل
          المعلومات الشخصية، على سبيل المثال لا الحصر:
        </p>
        <ul>
          <li>عنوان البريد الإلكتروني</li>
          <li>الاسم الأول والاسم الأخير</li>
          <li>رقم الهاتف</li>
          <li>العنوان، الولاية، المحافظة، الرمز البريدي، المدينة</li>
          <li>ملفات تعريف الارتباط وبيانات الاستخدام</li>
        </ul>

        <h3>بيانات الاستخدام</h3>
        <p>
          قد نقوم أيضًا بجمع معلومات حول كيفية وصول الخدمة واستخدامها ("بيانات
          الاستخدام"). قد تتضمن بيانات الاستخدام معلومات مثل عنوان بروتوكول
          الإنترنت لجهاز الكمبيوتر الخاص بك (مثل عنوان IP)، ونوع المتصفح، وإصدار
          المتصفح، والصفحات التي تزورها في خدمتنا، وتاريخ ووقت زيارتك، ومدة
          بقاءك في تلك الصفحات، ومعرفات الأجهزة الفريدة، وبيانات تشخيصية أخرى.
        </p>

        <br/>
        <strong>تتبع البيانات وملفات تعريف الارتباط</strong>
        <p>
          نستخدم ملفات تعريف الارتباط وتقنيات التتبع المماثلة لتتبع النشاط في
          خدمتنا والاحتفاظ بمعلومات معينة.
        </p>

        <br/>
        <strong>استخدام البيانات</strong>
        <p>تستخدم Linguist البيانات المجمعة لأغراض متنوعة:</p>
        <ul>
          <li>لتوفير وصيانة الخدمة</li>
          <li>لإعلامك بالتغييرات في خدمتنا</li>
          <li>
            لسماح لك بالمشاركة في الميزات التفاعلية لخدمتنا عندما تختار القيام
            بذلك
          </li>
          <li>لتقديم رعاية العملاء والدعم</li>
          <li>لتوفير تحليل أو معلومات قيمة لنتمكن من تحسين الخدمة</li>
          <li>لمراقبة استخدام الخدمة</li>
          <li>لاكتشاف ومنع ومعالجة المشكلات الفنية</li>
        </ul>

        <br/>
        <strong>نقل البيانات</strong>
        <p>
          قد يتم نقل معلوماتك، بما في ذلك البيانات الشخصية، إلى — والصيانة على —
          أجهزة كمبيوتر تقع خارج ولايتك أو مقاطعتك أو بلدك أو أي ولاية قضائية
          حكومية أخرى حيث قد تختلف قوانين حماية البيانات عن تلك التي في ولايتك.
        </p>
        <p>
          إذا كنت تقيم خارج المملكة العربية السعودية وتختار تزويدنا بمعلومات،
          يرجى ملاحظة أننا نقوم بنقل البيانات، بما في ذلك البيانات الشخصية، إلى
          المملكة العربية السعودية ومعالجتها هناك. تمثل موافقتك على سياسة
          الخصوصية هذه من خلال تقديم هذه المعلومات موافقتك على هذا النقل.
        </p>
        <p>
          سنتخذ Linguist جميع الخطوات الضرورية لضمان أن تتم معالجة بياناتك بأمان
          ووفقًا لهذه السياسة، ولن يتم نقل بياناتك الشخصية إلى منظمة أو دولة ما
          لم تكن هناك ضوابط كافية بما في ذلك أمن بياناتك والمعلومات الشخصية
          الأخرى.
        </p>

        <br/>
        <strong>الكشف عن البيانات</strong>
        <h3>المتطلبات القانونية</h3>
        <p>
          قد تكشف Linguist عن بياناتك الشخصية بناءً على الاعتقاد الحسن بأن هذا
          الإجراء ضروري لـ:
        </p>
        <ul>
          <li>الامتثال لالتزام قانوني</li>
          <li>حماية والدفاع عن حقوق أو ممتلكات Linguist</li>
          <li>منع أو التحقيق في أي خطأ محتمل فيما يتعلق بالخدمة</li>
          <li>حماية سلامة مستخدمي الخدمة أو الجمهور</li>
          <li>الحماية ضد المسؤولية القانونية</li>
        </ul>

        <br/>
        <strong>أمن البيانات</strong>
        <p>
          أمن بياناتك مهم بالنسبة لنا، ولكن تذكر أنه لا توجد وسيلة لنقل
          المعلومات عبر الإنترنت أو وسيلة لتخزين إلكتروني آمنة 100%. بينما نسعى
          لاستخدام وسائل مقبولة تجاريًا لحماية بياناتك الشخصية، لا يمكننا ضمان
          أمانها المطلق.
        </p>

        <br/>
        <strong>مقدمي الخدمة</strong>
        <p>
          قد نستخدم شركات وأفراد من طرف ثالث لتسهيل خدمتنا ("مقدمي الخدمة")،
          لتقديم الخدمة نيابة عنا، لأداء خدمات متعلقة بالخدمة أو لمساعدتنا في
          تحليل كيفية استخدام خدمتنا.
        </p>
        <p>
          تتمتع هذه الأطراف الثالثة بالوصول إلى بياناتك الشخصية فقط لأداء هذه
          المهام نيابة عنا وهي ملزمة بعدم الكشف عنها أو استخدامها لأي غرض آخر.
        </p>

        <br/>
        <strong>روابط لمواقع أخرى</strong>
        <p>
          قد تحتوي خدمتنا على روابط لمواقع أخرى غير مدارة من قبلنا. إذا قمت
          بالنقر على رابط لطرف ثالث، ستتم توجيهك إلى موقع ذلك الطرف الثالث. نوصي
          بشدة بمراجعة سياسة الخصوصية لكل موقع تزوره.
        </p>
        <p>
          ليس لدينا أي سيطرة على، ولا نتحمل أي مسؤولية عن محتوى أو سياسات
          الخصوصية أو ممارسات أي مواقع أو خدمات تابعة لجهات خارجية.
        </p>

        <br/>
        <strong>خصوصية الأطفال</strong>
        <p>لا تتوجه خدمتنا إلى أي شخص دون سن 18 عامًا ("الأطفال").</p>
        <p>
          نحن لا نجمع عمدًا بيانات تعريف شخصية من أي شخص دون سن 18 عامًا. إذا
          كنت أحد الوالدين أو الوصي وأنت على علم بأن أطفالك قد زودونا بمعلومات
          شخصية، يرجى الاتصال بنا. إذا أصبحنا على علم بأننا جمعنا بيانات شخصية
          من الأطفال دون تحقق من موافقة الوالدين، سنتخذ خطوات لإزالة تلك
          المعلومات من خوادمنا.
        </p>

        <br/>
        <strong>تغييرات في سياسة الخصوصية هذه</strong>
        <p>
          قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بإعلامك بأي
          تغييرات من خلال نشر سياسة الخصوصية الجديدة على هذه الصفحة.
        </p>
        <p>
          من المستحسن مراجعة سياسة الخصوصية هذه بشكل دوري لأي تغييرات. تصبح
          التغييرات في سياسة الخصوصية هذه سارية المفعول عند نشرها على هذه
          الصفحة.
        </p>

        <br/>
        <strong>اتصل بنا</strong>
        <p>إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا:</p>
        <ul>
          <li>
            عبر البريد الإلكتروني:{" "}
            <a href="mailto:yazeedalotibu@gmail.com">yazeedalotibu@gmail.com</a>
          </li>
          <li>
            من خلال زيارة هذه الصفحة على موقعنا:{" "}
            <a href="http://www.linguistedu.com/contact">
              www.linguistedu.com/contact
            </a>
          </li>
          <li>عبر رقم الهاتف: [+966 55 115 4538]</li>
        </ul>

        <br/>
        <strong>تم تطويره بواسطة:</strong>
        <ul>
          <li>
            البريد الإلكتروني للمطور:{" "}
            <a href="mailto:vimit@aariyatech.com">vimit@aariyatech.com</a>
          </li>
          <li>
            من خلال زيارة هذه الصفحة على موقعنا:{" "}
            <a href="http://www.aariyatech.com/contact">
              www.aariyatech.com/contact
            </a>
          </li>
          <li>عبر رقم الهاتف: [+91 7972225706]</li>
        </ul>
      </div>

      <hr />

      <div>
        <br/>
        <strong>Privacy Policy</strong>
        <p>
          <br/>
          <strong>Last Updated:</strong> [28-06-2024]
        </p>
        <p>
          Linguist ("us", "we", or "our") operates the website
          www.linguistedu.com (the "Service"). This page informs you of our
          policies regarding the collection, use, and disclosure of personal
          data when you use our Service and the choices you have associated with
          that data.
        </p>
        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy.
        </p>

        <br/>

        <strong>Information Collection and Use </strong>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>

        <br/>
        <strong>Types of Data Collected</strong>
        <h3>Personal Data</h3>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Cookies and Usage Data</li>
        </ul>

        <h3>Usage Data</h3>
        <p>
          We may also collect information on how the Service is accessed and
          used ("Usage Data"). This Usage Data may include information such as
          your computer's Internet Protocol address (e.g., IP address), browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique
          device identifiers, and other diagnostic data.
        </p>

        <br/>
        <strong>Tracking & Cookies Data</strong>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information.
        </p>

        <br/>
        <strong>Use of Data</strong>
        <p>Linguist uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent, and address technical issues</li>
        </ul>

        <br/>
        <strong>Transfer of Data</strong>
        <p>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country, or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </p>
        <p>
          If you are located outside Saudi Arabia and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to Saudi Arabia and process it there. Your consent to
          this Privacy Policy followed by your submission of such information
          represents your agreement to that transfer.
        </p>
        <p>
          Linguist will take all steps reasonably necessary to ensure that your
          data is treated securely and in accordance with this Privacy Policy,
          and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </p>

        <br/>
        <strong>Disclosure of Data</strong>
        <h3>Legal Requirements</h3>
        <p>
          Linguist may disclose your Personal Data in the good faith belief that
          such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of Linguist</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>

        <br/>
        <strong>Security of Data</strong>
        <p>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>

        <br/>
        <strong>Service Providers</strong>
        <p>
          We may employ third-party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf,
          to perform Service-related services, or to assist us in analyzing how
          our Service is used.
        </p>
        <p>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>

        <br/>
        <strong>Links to Other Sites</strong>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third-party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.
        </p>

        <br/>
        <strong>Children's Privacy</strong>
        <p>
          Our Service does not address anyone under the age of 18 ("Children").
        </p>
        <p>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your child has provided us with Personal Data, please
          contact us. If we become aware that we have collected Personal Data
          from children without verification of parental consent, we take steps
          to remove that information from our servers.
        </p>

        <br/>
        <strong>Changes to This Privacy Policy</strong>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <br/>
        <strong>Contact Us</strong>
        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <ul>
          <li>
            By email:{" "}
            <a href="mailto:yazeedalotibu@gmail.com">yazeedalotibu@gmail.com</a>
          </li>
          <li>
            By visiting this page on our website:{" "}
            <a href="http://www.linguistedu.com/contact">
              www.linguistedu.com/contact
            </a>
          </li>
          <li>By phone number: [+966 55 115 4538]</li>
        </ul>

        <br/>
        <strong>Developed By:</strong>
        <ul>
          <li>
            Developer email:{" "}
            <a href="mailto:vimit@aariyatech.com">vimit@aariyatech.com</a>
          </li>
          <li>
            By visiting this page on our website:{" "}
            <a href="http://www.aariyatech.com/contact">
              www.aariyatech.com/contact
            </a>
          </li>
          <li>By phone number: [+91 7972225706]</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
