import type { CookieConsentConfig } from "vanilla-cookieconsent";

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      position: "left",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: function () {
    console.log("onFirstAction fired");
  },

  onConsent: function ({ cookie }) {
    console.log("onConsent fired ...");
  },

  onChange: function ({ changedCategories, cookie }) {
    console.log("onChange fired ...");
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
  },

  language: {
    default: "ar",
    autoDetect: "browser",
    rtl: "ar",

    translations: {
      en: {
        consentModal: {
          title: "Hello Visitor",
          description:
            'LINGUISTEDU uses necessary cookies to personalize content and ads, to provide social media features, and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of their services <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Manage preferences</a>',
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage preferences",
        //   closeIconLabel: 'Close',
          footer: `
            <a  href="/assets/Privacy-Policy-Linguist_ORG.pdf"
                    target="_blank"
                    rel="noopener noreferrer">Privacy Policy</a>
          `,
        },
        preferencesModal: {
          title: "Cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Save preferences",
          closeIconLabel: "Close",
          sections: [
            {
              title: "Cookie Usage",
              description: `We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a  href="/assets/Privacy-Policy-Linguist_ORG.pdf"
                    target="_blank"
                    rel="noopener noreferrer" class="cc__link">privacy policy</a>.`,
            },
            {
              title: "Strictly necessary cookies",
              description: `Our website uses strictly necessary cookies to ensure its proper functioning and to enable you to navigate and use its essential features. These cookies are critical for the security, performance, and accessibility of the site. Without these cookies, services you have asked for, such as setting your privacy preferences, logging in, or filling out forms, cannot be provided.

By using our website, you agree to the use of these cookies. These cookies do not store any personally identifiable information and cannot be switched off in our systems.

Examples of Strictly Necessary Cookies we use:

Session Cookies: These cookies are temporary and are deleted once you close your browser. They help in maintaining your session on the website.
Security Cookies: These cookies are used to detect security threats and protect the website and its users from malicious activity.
Load Balancing Cookies: These cookies ensure that the website remains accessible by distributing the load across multiple servers.
For more details, you can refer to our <a href="/assets/Privacy-Policy-Linguist_ORG.pdf"  target="_blank"
                    rel="noopener noreferrer" class="cc__link">Privacy Policy</a>`,
              linkedCategory: "necessary",
            },
            {
              title: "Performance and Analytics cookies",
              linkedCategory: "analytics",
              cookieTable: {
                headers: {
                  name: "Name",
                  domain: "Service",
                  description: "Description",
                  expiration: "Expiration",
                },
                body: [
                  {
                    name: "_ga",
                    domain: "Google Analytics",
                    description:
                      'Cookie set by <a href="#das">Google Analytics</a>.',
                    expiration: "Expires after 12 days",
                  },
                  {
                    name: "_gid",
                    domain: "Google Analytics",
                    description:
                      'Cookie set by <a href="#das">Google Analytics</a>',
                    expiration: "Session",
                  },
                ],
              },
            },
            {
              title: "More information",
              description: `For any queries in relation to my policy on cookies and your choices, please <a class="cc__link"  href="mailto:admin@linguistedu.com"
                    target="_blank"
                    rel="noopener noreferrer">email us</a>.`,
            },
          ],
        },
      },
      ar: {
        consentModal: {
          title: "مرحبا الزائر",
          description: `تستخدم LINGUISTEDU ملفات تعريف الارتباط الضرورية لتخصيص المحتوى والإعلانات، وتوفير ميزات الوسائط الاجتماعية، وتحليل حركة المرور لدينا. نشارك أيضًا معلومات حول استخدامك لموقعنا مع شركائنا في وسائل التواصل الاجتماعي والإعلان والتحليلات الذين قد يجمعونها مع المعلومات الأخرى التي قدمتها لهم أو التي جمعوها من استخدامك لخدماتهم.
 <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">إدارة التفضيلات</a>`,
          acceptAllBtn: "قبول الكل",
          acceptNecessaryBtn: "رفض الكل",
          showPreferencesBtn: "إدارة التفضيلات",
          //closeIconLabel: 'Close',
          footer: `
              <a  href="/assets/Privacy-Policy-Linguist_ORG.pdf"
                      target="_blank"
                      rel="noopener noreferrer">سياسة الخصوصية</a>
            `,
        },
        preferencesModal: {
          title: "تفضيلات ملفات تعريف الارتباط",
          acceptAllBtn: "قبول الكل",
          acceptNecessaryBtn: "رفض الكل",
          savePreferencesBtn: "حفظ التفضيلات",
          closeIconLabel: "يغلق",
          sections: [
            {
              title: "استخدام ملفات تعريف الارتباط",
              description: `نحن نستخدم ملفات تعريف الارتباط لضمان الوظائف الأساسية للموقع ولتعزيز تجربتك عبر الإنترنت. يمكنك اختيار الاشتراك/الخروج لكل فئة وقتما تشاء. لمزيد من التفاصيل المتعلقة بملفات تعريف الارتباط والبيانات الحساسة الأخرى، يرجى قراءة النص بالكامل
<a  href="/assets/Privacy-Policy-Linguist_ORG.pdf"
                      target="_blank"
                      rel="noopener noreferrer" class="cc__link">سياسة الخصوصية</a>.`,
            },
            {
              title: "ملفات تعريف الارتباط الضرورية للغاية",
              description: `يستخدم موقعنا ملفات تعريف الارتباط الضرورية للغاية لضمان حسن سير العمل ولتمكينك من التنقل واستخدام ميزاته الأساسية. تعتبر ملفات تعريف الارتباط هذه ضرورية لأمن الموقع وأدائه وإمكانية الوصول إليه. بدون ملفات تعريف الارتباط هذه، لا يمكن توفير الخدمات التي طلبتها، مثل تعيين تفضيلات الخصوصية الخاصة بك، أو تسجيل الدخول، أو ملء النماذج.
  
  باستخدام موقعنا، فإنك توافق على استخدام ملفات تعريف الارتباط هذه. لا تخزن ملفات تعريف الارتباط هذه أي معلومات تعريف شخصية ولا يمكن إيقاف تشغيلها في أنظمتنا.
  
  أمثلة على ملفات تعريف الارتباط الضرورية للغاية التي نستخدمها:
  
  ملفات تعريف الارتباط الخاصة بالجلسة: ملفات تعريف الارتباط هذه مؤقتة ويتم حذفها بمجرد إغلاق المتصفح. أنها تساعد في الحفاظ على جلستك على الموقع.
  ملفات تعريف الارتباط الأمنية: تُستخدم ملفات تعريف الارتباط هذه للكشف عن التهديدات الأمنية وحماية موقع الويب ومستخدميه من الأنشطة الضارة.
  ملفات تعريف الارتباط لموازنة التحميل: تضمن ملفات تعريف الارتباط هذه إمكانية الوصول إلى موقع الويب من خلال توزيع الحمل عبر خوادم متعددة.
  لمزيد من التفاصيل، يمكنك الرجوع إلى موقعنا
 <a href="/assets/Privacy-Policy-Linguist_ORG.pdf"  target="_blank"
                      rel="noopener noreferrer" class="cc__link">سياسة الخصوصية</a>`,
              linkedCategory: "necessary",
            },
            {
              title: "ملفات تعريف الارتباط الخاصة بالأداء والتحليلات",
              linkedCategory: "analytics",
              cookieTable: {
                headers: {
                  name: "Name",
                  domain: "Service",
                  description: "Description",
                  expiration: "Expiration",
                },
                body: [
                  {
                    name: "_ga",
                    domain: "Google Analytics",
                    description:
                      'Cookie set by <a href="#das">Google Analytics</a>.',
                    expiration: "Expires after 12 days",
                  },
                  {
                    name: "_gid",
                    domain: "Google Analytics",
                    description:
                      'Cookie set by <a href="#das">Google Analytics</a>',
                    expiration: "Session",
                  },
                ],
              },
            },
            {
              title: "معلومات اكثر",
              description: `إذا كانت لديك أية استفسارات فيما يتعلق بسياستي بشأن ملفات تعريف الارتباط واختياراتك، من فضلك <a class="cc__link"  href="mailto:admin@linguistedu.com"
                      target="_blank"
                      rel="noopener noreferrer">ارسل لنا عبر البريد الإلكتروني</a>.`,
            },
          ],
        },
      },
    },
  },
};

export default pluginConfig;
