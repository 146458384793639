import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "../../providers";
import LanguageToggler from "./LangToggler";
import ThemeToggler from "./ThemeToggler";
import menuData from "./menuData";

const Header = () => {
  // Navbar toggle
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { isRTL } = useTheme();
  const { t } = useTranslation();

  const navbarToggleHandler = () => {
    setNavbarOpen(!navbarOpen);
  };

  // Sticky Navbar
  const [sticky, setSticky] = useState(false);
  const handleStickyNavbar = () => {
    if (window.scrollY >= 80) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleStickyNavbar);
    return () => {
      window.removeEventListener("scroll", handleStickyNavbar);
    };
  }, []);

  const location = useLocation();

  // Accessing pathname from location object
  const currentPath = location.pathname;

  // submenu handler
  const [openIndex, setOpenIndex] = useState(-1);
  const handleSubmenu = (index: any) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  const filteredMenu = menuData
    .map((m) => {
      return {
        ...m,
        title: t(`common.${m?.title}`),
      };
    })
    .sort((a, b) => (isRTL ? b.id - a.id : a.id - b.id));

    if(currentPath === "/privacy-policy"){
      return <></>;
    }
  return (
    <>
      <header
        className={`header left-0 top-0 z-40 flex w-full items-center ${
          sticky
            ? "fixed z-[9999] bg-white !bg-opacity-80 shadow-sticky backdrop-blur-sm transition dark:bg-gray-dark dark:shadow-sticky-dark"
            : "absolute bg-transparent"
        } ${isRTL ? "rtl" : ""}`}
      >
        <div className="container">
          <div
            className={`relative -mx-4 flex items-center justify-between ${
              isRTL ? "flex-row-reverse" : ""
            } `}
          >
            <div className="w-60 max-w-full px-4 xl:mr-12">
              <Link
                to="/"
                className={`header-logo block w-full ${
                  sticky ? "py-5 lg:py-2" : "py-8"
                }`}
              >
                <img
                  src="/logo/Logo.png"
                  alt="logo"
                  width={150}
                  height={30}
                  className="w-full dark:hidden"
                />
                <img
                  src="/logo/Logo-Gray.png"
                  alt="logo"
                  width={250}
                  height={50}
                  className="hidden  dark:block"
                />
              </Link>
            </div>
            <div
              className={`flex w-full items-center justify-between px-4 ${
                isRTL ? "flex-row-reverse" : ""
              }`}
            >
              <div className="flex items-center hidden  lg:flex justify-end pr-16 lg:pr-0 ">
                <div>
                  <ThemeToggler />
                </div>
                <div>
                  <LanguageToggler />
                </div>
              </div>
              <div>
                <button
                  onClick={navbarToggleHandler}
                  id="navbarToggler"
                  aria-label="Mobile Menu"
                  className={`absolute ${
                    !isRTL ? "right-4" : "left-4"
                  }  translate-y-[-50%] top-1/2 block rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden `}
                >
                  <span
                    className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
                      navbarOpen ? " top-[7px] rotate-45" : " "
                    }`}
                  />
                  <span
                    className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
                      navbarOpen ? "opacity-0 " : " "
                    }`}
                  />
                  <span
                    className={`relative my-1.5 block h-0.5 w-[30px] bg-black transition-all duration-300 dark:bg-white ${
                      navbarOpen ? " top-[-8px] -rotate-45" : " "
                    }`}
                  />
                </button>
                <nav
                  id="navbarCollapse"
                  className={`navbar absolute  ${
                    !isRTL ? "right-0" : "left-0"
                  } z-30 w-[250px] rounded border-[.5px] border-body-color/50 bg-white px-6 py-4 duration-300 dark:border-body-color/20 dark:bg-dark lg:visible lg:static lg:w-auto lg:border-none lg:!bg-transparent lg:p-0 lg:opacity-100 ${
                    navbarOpen
                      ? "visibility top-full opacity-100"
                      : "invisible top-[120%] opacity-0"
                  }`}
                >
                  <ul
                    className={`block lg:flex lg:space-x-12 ${
                      isRTL ? "rtl" : ""
                    }`}
                  >
                    {filteredMenu.map((menuItem, index) => (
                      <li key={index} className="group relative">
                        {menuItem.path ? (
                          <Link
                            to={menuItem.path}
                            className={`flex py-2 text-base lg:mr-0 lg:inline-flex lg:px-0 lg:py-6 ${
                              currentPath === menuItem.path
                                ? "text-primary dark:text-white"
                                : "hover:text-hover text-dark dark:text-white/70 dark:hover:text-white"
                            }`}
                          >
                            {menuItem.title}
                          </Link>
                        ) : (
                          <>
                            <p
                              onClick={() => handleSubmenu(index)}
                              className="flex cursor-pointer items-center justify-between py-2 text-base text-dark  group-hover:text-primary dark:text-white/70 dark:group-hover:text-white lg:mr-0 lg:inline-flex lg:px-0 lg:py-6"
                            >
                              {menuItem.title}
                              <span className="pl-3">
                                <svg width="25" height="24" viewBox="0 0 25 24">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </p>
                            <div
                              className={`submenu relative left-0 top-full rounded-sm bg-white transition-[top] duration-300 group-hover:opacity-100 dark:bg-dark lg:invisible lg:absolute lg:top-[110%] lg:block lg:w-[250px] lg:p-4 lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full ${
                                openIndex === index ? "block" : "hidden"
                              }`}
                            >
                              {menuItem.submenu.map(
                                (submenuItem: any, index: any) => (
                                  <Link
                                    to={submenuItem.path}
                                    key={index}
                                    className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                                  >
                                    {submenuItem.title}
                                  </Link>
                                )
                              )}
                            </div>
                          </>
                        )}
                      </li>
                    ))}
                    <li>
                      <div className="flex items-center justify-end pr-16 lg:pr-0 lg:hidden">
                        <div>
                          <ThemeToggler />
                        </div>
                        <div>
                          <LanguageToggler />
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
