import { useTranslation } from "react-i18next";
import { useTheme } from "../../providers";

const AboutSectionTwo = () => {
  const { t } = useTranslation();
  const { isRTL } = useTheme();

  return (
    <section className="py-16 md:py-20 lg:py-28">
      <div className="container">
        <div
          className={`-mx-4 flex flex-wrap items-center ${isRTL ? "rtl" : ""}`}
        >
          <div className={`w-full px-4 lg:w-1/2 ${isRTL ? "lg:order-2" : ""}`}>
            <div
              className="relative mx-auto mb-12 aspect-[25/24] max-w-[500px] text-center lg:m-0"
              data-wow-delay=".15s"
            >
               {/* eslint-disable-next-line */}
              <img
                src="/images/about/about-image-2.svg"
                alt="about imagel"
                className="drop-shadow-three dark:hidden dark:drop-shadow-none"
              />
              {/* eslint-disable-next-line */}
              <img
                src="/images/about/about-image-2-dark.svg"
                alt="about imaged"
                className="hidden drop-shadow-three dark:block dark:drop-shadow-none"
              />
            </div>
          </div>
          <div className={`w-full px-4 lg:w-1/2 ${isRTL ? "lg:order-1" : ""}`}>
            <div
              className={`max-w-[470px] ${isRTL ? "text-right" : "text-left"}`}
            >
              <div className="mb-9">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  {t("about2.title1")}
                </h3>
                <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                  {t("about2.description1")}
                </p>
              </div>
              <div className="mb-9">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  {t("about2.title2")}
                </h3>
                <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                  {t("about2.description2")}
                </p>
              </div>
              <div className="mb-1">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  {t("about2.title3")}
                </h3>
                <p className="text-base font-medium leading-relaxed text-body-color sm:text-lg sm:leading-relaxed">
                  {t("about2.description3")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSectionTwo;
