import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useTranslation } from "react-i18next";

type Theme = "light" | "dark";

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
  isRTL: boolean;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

interface ProvidersProps {
  children: ReactNode;
}

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const localTheme = localStorage.getItem("linguist_them_choice");
  const [theme, setTheme] = useState<Theme>((localTheme as Theme) || "light");
  const [isRTL, setIsRTL] = useState<boolean>(currentLanguage === "ar");

  const toggleTheme = () => {
    localStorage.setItem(
      "linguist_them_choice",
      theme === "light" ? "dark" : "light"
    );
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    setIsRTL(currentLanguage === "ar");
  }, [currentLanguage]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isRTL }}>
      {children}
    </ThemeContext.Provider>
  );
};
