import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import pluginConfig from "./CookiesConsentConfig";

const CookieConsentComponent = () => {
  useEffect(() => {
    CookieConsent.run(pluginConfig);
  }, []);

  return (
    // eslint-disable-next-line
    <a
      href="/"
      className="text-sm p-2 inline-block text-blue-500 hover:text-blue-700"
      onClick={CookieConsent.showPreferences}
    >
      Show Cookie Preferences
    </a>
  );
};

export default CookieConsentComponent;
