import { useTranslation } from "react-i18next";
import AboutSectionOne from "../../About/AboutSectionOne";
import AboutSectionTwo from "../../About/AboutSectionTwo";
import Breadcrumb from "../../Common/Breadcrumb";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb
        pageName={t("about.title")}
        description={t("about.subtitle")}
      />
      <AboutSectionOne />
      <AboutSectionTwo />
    </>
  );
};

export default AboutPage;
