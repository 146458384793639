import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "./components/Contact";
import Layout from "./components/Layout";
import AboutPage from "./components/pages/about";
import Home from "./components/pages/home";
import PaymentStatus from "./components/PaymentStatus/Index";
import { Providers } from "./providers"; // Assuming Providers exports correctly
import PrivacyPolicy from "./components/PrivacyPolicy";

const App: React.FC = () => {
  return (
    <Providers>
      <Routes>
        <Route path="payment-status" element={<PaymentStatus />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/" element={<Layout children={undefined} />}>
          <Route index path="/" element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </Providers>
  );
};

export default App;
