import ScrollUp from "../../Common/ScrollUp";
import Contact from "../../Contact";
import Features from "../../Features";
import Hero from "../../Hero";

function Home() {
  console.log("in home page");

  return (
    <div>
      <ScrollUp />
      <Hero />
      <Features />
      <Contact />
    </div>
  );
}

export default Home;
