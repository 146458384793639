import { useTranslation } from "react-i18next";
import { useTheme } from "../../providers";

const LanguageToggler = () => {
  const { i18n } = useTranslation();
  const { isRTL } = useTheme();

  const toggleLanguage = () => {
    localStorage.setItem(
      "linguist_language",
      i18n?.language === "ar" ? "en" : "ar"
    );
    i18n.changeLanguage(i18n?.language === "ar" ? "en" : "ar");
  };

  return (
    <div className="flex text-sm items-center justify-center rounded-lg bg-white dark:bg-gray-dark ">
      <div className="inline-flex rounded-md  p-1">
        <button
          className={`toggle-button rounded-md px-2 py-1 focus:outline-none
             ${
               isRTL
                 ? "bg-green-500 text-white"
                 : "text-body-color dark:text-body-color-dark"
             }
          `}
          onClick={() => toggleLanguage()}
        >
          AR
        </button>
        <button
          className={`toggle-button rounded-md px-2 py-1 focus:outline-none ${
            !isRTL
              ? "bg-green-500 text-white"
              : "text-body-color dark:text-body-color-dark"
          }`}
          onClick={() => toggleLanguage()}
        >
          EN
        </button>
      </div>
    </div>
  );
};

export default LanguageToggler;
