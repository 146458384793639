const GradientLoader = () => (
  <div className="flex space-x-2 justify-center items-center bg-white dark:invert">
    <span className="h-8 w-8 bg-gradient-to-r from-[#6CEDA7] to-[#4BC7A7] rounded-full animate-bounce [animation-delay:-0.3s]"></span>
    <span className="h-8 w-8 bg-gradient-to-r from-[#6CEDA7] to-[#4BC7A7] rounded-full animate-bounce [animation-delay:-0.2s]"></span>
    <span className="h-8 w-8 bg-gradient-to-r from-[#6CEDA7] to-[#4BC7A7] rounded-full animate-bounce [animation-delay:-0.1s]"></span>
    <span className="h-8 w-8 bg-gradient-to-r from-[#6CEDA7] to-[#4BC7A7] rounded-full animate-bounce"></span>
  </div>
);

export default GradientLoader;
