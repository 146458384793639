import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import entranslation from "./en/translation.json";
import artranslation from "./ar/translation.json";

const localLanguage = localStorage.getItem("linguist_language");

i18next.use(initReactI18next).init({
  lng: localLanguage || "en", // if you're using a language detector, do not define the lng option
  fallbackLng: localLanguage || "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: entranslation,
    },
    ar: {
      translation: artranslation,
    },
  },
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});

export default i18next;
